import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="News Feed Tablet App Template | Uizard"
    canonical="/templates/mobile-app-templates/news-feed-mobile-app/"
    category="Tablet Templates"
    url="/templates/tablet-templates/news-feed-tablet-app/"
    metaDescription="Introducing NewsPulse, Uizard's news feed app design template for tablet interfaces. Create your own news feed app design today."
    description="
    h2:NewsPulse: Uizard's news feed app design template for tablet
    <br/>
    Hot off the press: Introducing NewsPulse, our news feed <a:https://uizard.io/templates/tablet-templates/>tablet design template</a>. Our app template comes with everything you need to build your own news feed app design. Either use the template straight out of the box or take advantage of Uizard's myriad of design components to really put your own spin on things.
    <br/>
    h3:A contemporary design for every headline
    <br/>
    Breaking news: NewsPulse comes with all the features you could possibly need to create your own news feed app design; with Uizard, it's so easy to adapt the design to suit your own project. Easily duplicate screens, add new ones, edit color schemes, and tweak the typography to build your own vision.
    <br/>
    h3:You make the news; customize and collaborate
    <br/>
    Make the headlines with your own news feed app design with the NewsPulse template today. Working as part of a team? Uizard's smart sharing features streamline collaboration like never before. Add new teammates, leave comments for colleagues, and <a:https://uizard.io/prototyping/>prototype</a> rapidly.
    "
    pages={[
      "A stunning sign up/subscribe landing page with a chic, minimalist design",
      "A pricing page to demonstrate subscription tiers for your newsfeed app design",
      "Interest selection screen with natural and clear calls to action to move through the app interface",
    ]}
    projectCode="qRGyBzMy95uyKqQ35pgX"
    img1={data.image1.childImageSharp}
    img1alt="news feed tablet app design template cover"
    img2={data.image2.childImageSharp}
    img2alt="news feed tablet app design template article detail screen"
    img3={data.image3.childImageSharp}
    img3alt="news feed tablet app design template saved articles screen"
    img4={data.image4.childImageSharp}
    img4alt="news feed tablet app design template article end screen"
    img5={data.image5.childImageSharp}
    img5alt="news feed tablet app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/news-feed-tablet-app/news-tab-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/news-feed-tablet-app/news-tab-app-article-detail.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/news-feed-tablet-app/news-tab-app-saved.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/news-feed-tablet-app/news-tab-app-article-end.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/news-feed-tablet-app/news-tab-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
